html,body {
    font-family: 'Sarabun', sans-serif;
    background-color: #111111;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Lora', serif;
}

ul {
    list-style: disc inside;
}

a {
    text-decoration: underline;
    color: rgb(65, 205, 127, 1);
}